<template>
  <div class="payment-bid-main">
    <div class="dashboard-header">
      <a @click="$router.go(-1)">
        <figure>
          <img :src="require('@assets/images/back-circle-arrow.png')" alt="" />
        </figure>
        Back
      </a>
    </div>
    <div class="payment-wrapper">
      <div class="abr-body">
        <div class="abr-title">
          <h4>Accept bid request</h4>
          <!-- <p>
            Please fill up the payment details<img
              :src="require('@assets/images/black-lock.svg')"
              alt=""
            />
          </p> -->
        </div>
        <div
          class="spinner-border text-primary"
          role="status"
          v-if="isFetchingData"
          style="left: 50%; margin-left: 50em"
        ></div>
        <div class="abr-fold">
          <div
            class="abr-left"
            v-if="Bookingdata && Bookingdata !== 'null' && Bookingdata !== 'undefined'"
          >
            <h4>{{ Bookingdata.title_of_booking }}</h4>
            <ul>
              <li>
                <figure>
                  <img :src="require('@assets/images/pin-sharp-circle.svg')" />
                </figure>
                <div class="detail">
                  <div class="location">PICK-UP LOCATION</div>
                  <div class="address">{{ Bookingdata.pickup_address }}</div>
                  <!-- <div class="schedule">
                    <div class="time">07:34 AM</div>
                    <div class="date">5th Apr 2022</div>
                  </div> -->
                  <div class="other">
                    <h5>{{ Bookingdata.pick_name }}</h5>
                    <div class="mnum">{{ Bookingdata.pick_number }}</div>
                    <div class="eid">{{ Bookingdata.pick_email }}</div>
                  </div>
                </div>
              </li>
              <li>
                <figure>
                  <img :src="require('@assets/images/pin-sharp-dot.svg')" />
                </figure>
                <div class="detail">
                  <div class="location">DROP-OFF LOCATION</div>
                  <div class="address">{{ Bookingdata.drop_address }}</div>
                  <div class="schedule">
                    <div class="time">
                      {{ Bookingdata.service_time }}
                    </div>
                    <div class="date">
                      {{ moment(Bookingdata.service_date).format('Do MMM YYYY') }}
                    </div>
                  </div>
                  <div class="other">
                    <h5>{{ Bookingdata.drop_name }}</h5>
                    <div class="mnum">{{ Bookingdata.drop_number }}</div>
                    <div class="eid">{{ Bookingdata.drop_email }}</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div
            class="abr-right"
            v-if="Driverdata && Driverdata !== 'null' && Driverdata !== 'undefined'"
          >
            <div class="driver-detail">
              <h4>Driver details</h4>
              <div class="dd-fold">
                <figure>
                  <avatar
                    class="w-100 h-100"
                    v-if="Driverdata.user.image_url == null"
                    :username="Driverdata.user.full_name"
                  ></avatar>
                  <img
                    v-if="Driverdata.user.image_url != null"
                    :src="Driverdata.user.image_url"
                    alt=""
                  />
                </figure>
                <div class="dinfo">
                  <div class="dpinfo">
                    <h5>{{ Driverdata.user.full_name }}</h5>
                    <star-rating
                      :star-size="16"
                      :increment="0.5"
                      :rating="parseFloat(Driverdata.user.user_driver_rating_review_avg)"
                      read-only
                      :show-rating="false"
                      active-color="#00A6E3"
                    ></star-rating>
                  </div>
                  <div class="dverication">
                    <div class="verify">
                      <Verification
                        :verification-data="Driverdata.driver_documents_verified_or_not"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="damount">
              <h5>Total amount</h5>
              <div class="price">{{ Driverdata.total_amount }} <span>AUD</span></div>
            </div>
          </div>
        </div>
        <button
            v-if="!isFetchingData"
            type="button"
            :disabled="
              isbidAccepted ||
              isFetchingData ||
              bidStatus != 0
            "
            class="btn btn-primary"
            @click="submit"
          >
              <span
                v-if="isbidAccepted"
                class="spinner-border spinner-border-lg me-2"
                role="status"
                aria-hidden="true"
              ></span>
              <span v-if="!isbidAccepted">
            <img :src="require('@assets/images/lock-white.svg')" alt="" /> Submit
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import moment from 'moment';
import Avatar from 'vue-avatar';
import StarRating from 'vue-star-rating';
import Verification from '@/views/pages/DriverComponents/Verification.vue';

export default {
  name: 'Payment',
  components: {
    Avatar,
    StarRating,
    Verification,
  },
  data() {
    return {
      isbidAccepted: false,
      isFetchingData: false,
      Bookingdata: null,
      Driverdata: null,
      pickup_contact_name: JSON.parse(localStorage.getItem('userData')).full_name ?? '',
      pickup_contact_email: JSON.parse(localStorage.getItem('userData')).email ?? '',
      pickup_contact: JSON.parse(localStorage.getItem('userData')).mobile ?? '',
      rows: [],
      bookingId: this.$route.params.bookingId,
      bidId: this.$route.params.bidId,
      placed_id: 0,
      bidStatus: 0,
    };
  },
  created() {
    this.getspecificBooking(this.bookingId);
    this.showDriverProfile(this.bookingId, this.bidId);
  },
  methods: {
    moment,
    submit() {
      this.$swal
        .fire({
          html: 'Are you sure you want to accept this bid?',
          icon: 'info',
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonColor: '#00A6E3',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.bidAccept();
          }
        });
    },

    getspecificBooking(bookingId) {
      this.isFetchingData = true;
      this.$http
        .get(`/customer/booking/show/${bookingId}`)
        .then((res) => {
          const collection1 = res.data.data;
          this.Bookingdata = collection1;
          const serviceDateTimeDBUTC = `${collection1.service_date} ${collection1.service_time}`;
          const serviceDateTimeDBLocal = moment.utc(serviceDateTimeDBUTC).local().format('YYYY-MM-DD HH:mm:ss');
          this.Bookingdata.service_time = moment(serviceDateTimeDBLocal.substring(serviceDateTimeDBLocal.indexOf(' ') + 1), ['HH.mm']).format('hh:mm a');
          this.Bookingdata.service_date = serviceDateTimeDBLocal.substring(0, serviceDateTimeDBLocal.indexOf(' '));
        })
        .catch((error) => {
          this.Bookingdata = null;
          this.$toast.error('Booking not found');
        })
        .finally(() => {
          this.isFetchingData = false;
        });
    },
    showDriverProfile(bookingId, bidId) {
      this.isFetchingData = true;
      return this.$http
        .get(`customer/booking/show/${bookingId}/driver_profile/${bidId}`)
        .then((res) => {
          this.Driverdata = res.data.data;
          this.placed_id = res.data.data.id;
          this.bidStatus = res.data.data.bid_status;
        })
        .catch((error) => {
          this.$toast.error('Driver not found');
        })
        .finally(() => {
          this.isFetchingData = false;
        });
    },
    bidAccept() {
      this.isbidAccepted = true;
      this.$http
        .post('/customer/booking/bid_accept', {
          booking_id: this.bookingId,
          place_bid_id: this.placed_id,
        })
        .then((res) => {
          this.$router.push({ name: 'bookings' });
          this.$toast.open('Bid accepted successfully', 'success');
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.isbidAccepted = false;
          // this.createPaymentIntent(this.placed_id);
        });
    },
  },
};
</script>
